<template>
    <div class="cont not-check">
       <!-- 面包屑 AIOT 酒店管理 房间管理 房间设备 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$route.query.need === 'return'">
             <el-breadcrumb-item>AIOT</el-breadcrumb-item>
             <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
             <el-breadcrumb-item>房间管理</el-breadcrumb-item>
             <el-breadcrumb-item to="/room_device">房间设备</el-breadcrumb-item>
             <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
          <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
             <el-breadcrumb-item>AIOT</el-breadcrumb-item>
             <el-breadcrumb-item>酒店设备</el-breadcrumb-item>
             <el-breadcrumb-item>设备列表</el-breadcrumb-item>
             <el-breadcrumb-item> {{ fullPathEvent(this.$route.fullPath) }}</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <el-row class="content-box">
          <!-- 表头 -->
         <el-row class="cascader-height">
           <el-link v-if="need === 'return'" type="primary" class="back_iconfont" icon="iconfont iconfanhui1" @click="goBack()">
              <span style="font-size: 14px; margin-right: 15px">返回</span>
           </el-link>
         </el-row>
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label v-text="$t('msg.hotel_a')">酒店：</label>
                  <el-select
                        class="width-220"
                        v-model="hotelId"
                        @change="changeHotel"
                        :placeholder="$t('msg.select')">
                     <el-option v-for="item in hotelList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label v-text="$t('msg.building_unit_a')">楼栋/单元：</label>
                  <el-cascader
                        v-model="buildUnitId"
                        :options="buildList"
                        @change="changeBuild"
                        clearable

                        class="width-220">
                  </el-cascader>
               </el-row>
               <el-row class="search-item">
                  <label>设备编码：</label>
                  <el-input  class="width-220" v-model="deviceNameQ" :placeholder="$t('msg.dev_number')" clearable></el-input>
               </el-row>
            </el-row>
            <el-row class="search-row" >
               <el-row class="search-item" >
                  <label v-text="$t('msg.room_num_a')">房间号：</label>
                  <el-select
                        class="width-220"
                        v-model="roomId"
                        filterable
                        :placeholder="$t('msg.select')"
                        clearable>
                     <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button  type="primary" @click="handleQuery(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <el-row class="table-box">
            <el-row class="table-head-btn">
               <el-button-group class="m-right-10">
                  <el-button :type="state === 'ONLINE' ? 'primary' : ''" @click="state = 'ONLINE'" ><span v-text="$t('msg.online')">在线</span></el-button>
                  <el-button :type="state === 'OFFLINE' ? 'primary' : ''" @click="state = 'OFFLINE'" ><span v-text="$t('msg.offline')">离线</span></el-button>
                  <el-button :type="state === 'UNACTIVE' ? 'primary' : ''" @click="state = 'UNACTIVE'" ><span v-text="$t('msg.inactivated')">未激活</span></el-button>
               </el-button-group>
            </el-row>
            <el-row class="floor-bottom">
               <hotel-floor :floorOpt="floors" @setFloorClick="clickFloors"/>
            </el-row>
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    highlight-current-row
                    row-key="id"
                    lazy
                    :load="load"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    style="width: 100%"
                    min-height="780"
                    max-height="780"
                    :stripe="true"
                    border fit
                    >
                <el-table-column :label="$t('msg.number')" width="80">
                   <template slot-scope="scope">
                      {{scope.$index + 1}}
                   </template>
                </el-table-column>
                <el-table-column prop="deviceName" :label="$t('msg.dev_name')" min-width="160"></el-table-column>
                <el-table-column prop="versionsName" :label="$t('msg.dev_type')" :min-width="typeWidth"></el-table-column>
                <el-table-column :label="$t('msg.status')" min-width="100">
                    <template slot-scope="scope">
                        <span class="box-span mini bg-green" v-if="scope.row.state === 'ONLINE'">在线</span>
                        <span class="box-span mini bg-red" v-else-if="scope.row.state === 'OFFLINE'">离线</span>
                        <span class="box-span mini bg-gray" v-else>未知</span>
                    </template>
                </el-table-column>
                <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="160"></el-table-column>
                <el-table-column :label="$t('msg.floor_room')" min-width="130" sortable>
                    <template slot-scope="scope">
                       <span v-if="scope.row.floorNo">{{scope.row.floorNo}}层</span>
                       <span v-else>—</span>
                       <span v-text="'/'"></span>
                       <span v-if="scope.row.roomNo">{{scope.row.roomNo}}</span>
                       <span v-else>—</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('msg.install_location')">
                   <template slot-scope="scope">
                      {{ scope.row.location | filterLocation(that) }}
                   </template>
                </el-table-column>
                <el-table-column prop="lastOnlineTime" :label="$t('msg.last_online_time')" min-width="180" sortable></el-table-column>
                <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-warning-outline" @click="handleDetail(scope.row)" >
                            <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                        </el-button>
                        <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                            <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                        </el-button>
                        <el-button v-if="scope.row.deviceTypeId !== '100'" type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                            <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                        </el-button>
                        <el-button
                                v-if="scope.row.state === 'OFFLINE'
                                   && scope.row.deviceTypeId !== '100'
                                   && scope.row.productId.indexOf('WF') === -1"
                                   @click="handleAwaken(scope.row.parentId)"
                                   type="text" >
                            <el-link type="success" plain v-text="$t('msg.wake')">一键唤醒</el-link>
                        </el-button>
                       <!-- 智能锁 -->
                        <el-button
                                v-if="scope.row.deviceTypeId === '110'
                                   && scope.row.state === 'ONLINE'"
                                   @click="setLock(scope.row)"
                                   type="text" >
                            <el-link type="primary" plain v-text="$t('msg.lock')">开锁</el-link>
                        </el-button>
                        <!-- 温控面板 -->
                        <el-button
                                v-if="scope.row.deviceTypeId === '112'"
                                @click="getTemp(scope.row)"
                                type="text"
                                >
                            <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                        </el-button>
                        <!-- 开关面板 -->
                        <el-button
                                v-if="scope.row.state === 'ONLINE' &&
                                      scope.row.deviceTypeId === '101' &&
                                      scope.row.productId.substring(6, 8) !== '11'"
                                @click="getSwitch(scope.row)" type="text" >
                            <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                        </el-button>
                       <!-- 调光面板 -->
                       <el-button
                               v-if="scope.row.state === 'ONLINE' &&
                                     scope.row.productId.substring(0, 8) === 'HOSWZB11'"
                               @click="getDimming(scope.row)" type="text" >
                          <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                       </el-button>
                        <!-- 场景面板 -->
                        <el-button
                                v-if="scope.row.state === 'ONLINE' &&
                                      scope.row.deviceTypeId === '102'"
                                type="text" icon="" >
                            <el-link type="primary" plain></el-link>
                        </el-button>
                       <!-- 窗帘电机 -->
                        <el-button
                                v-if="scope.row.state === 'ONLINE' &&
                                scope.row.deviceTypeId === '108'"
                                @click="getCurtainMotor(scope.row)" type="text" icon="" >
                            <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                        </el-button>
                        <!-- wifi插座 -->
                        <el-button
                                v-if="scope.row.state === 'ONLINE' &&
                                scope.row.deviceTypeId === '103'"
                                @click="getSocket(scope.row)" type="text" icon="" >
                            <el-link type="primary" plain v-text="$t('msg.manipulation')">操控</el-link>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
         <!-- 开关面板 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.change_status')" :visible.sync="dialogSwitch" width="600px">
                <el-row class="m-bottom-15" v-for="(item, index) in switchList" :key="index">
                    <el-col :span="6" class="t-right"><span class="l-height-26">第{{index + 1}}位别名：</span></el-col>
                    <el-col :span="6">
                        <p class="l-height-26">{{item.alias || '-'}}</p>
                    </el-col>
                    <el-col :span="6" class="t-right"><span class="l-height-26">第{{index + 1}}位状态：</span></el-col>
                    <el-col :span="6" class="l-height-26">
                        <el-switch
                                @change="setSwitch(item.state, index)"
                                style="margin: 0px 5px;"
                                v-model="item.state"
                                active-value="TURNON"
                                inactive-value="TURNOFF"
                                active-color="#67c23a"
                                inactive-color="#dcdfe6">
                        </el-switch>
                    </el-col>
                </el-row>
            </el-dialog>
         </el-form>
         <!-- 调光面板 -->
         <el-form label-width="105px">
            <el-dialog title="控制亮度" :visible.sync="dialogDimming" width="600px">
               <el-row class="m-bottom-15">
                  <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                  <el-col :span="17">
                     <el-slider
                              class="p-left-10"
                              @change="setDimming"
                              v-model="currentLevel"
                              :min="1"
                              :max="254">
                     </el-slider>
                  </el-col>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 窗帘电机 -->
         <el-form label-width="105px" style="line-height: 26px;">
               <el-dialog :title="$t('msg.curtain_open_close_socket')" :visible.sync="dialogCurtain" width="510px">
                  <div class="config-box">
                     <el-row class="m-bottom-15">
                           <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                           <el-col :span="17">
                              <el-radio-group @change="setCurtainMotor" v-model="curtainState">
                                 <el-radio label="TURNON">打开</el-radio>
                                 <el-radio label="TURNOFF">关闭</el-radio>
                                 <el-radio label="PAUSE">停止</el-radio>
                              </el-radio-group>
                           </el-col>
                     </el-row>
                  </div>
               </el-dialog>
         </el-form>
         <!-- WIFI插座 -->
         <el-form label-width="105px" style="line-height: 26px;">
               <el-dialog :title="$t('msg.wifi_open_close_socket')" :visible.sync="dialogSocket" width="510px">
                  <div class="config-box">
                     <el-row class="m-bottom-15">
                           <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                           <el-col :span="17">
                              <el-switch
                                       v-model="socketState"
                                       @change="setSocket"
                                       active-value="TURNON"
                                       inactive-value="TURNOFF">
                              </el-switch>
                           </el-col>
                     </el-row>
                  </div>
               </el-dialog>
         </el-form>
         <!-- WIFI温控面板 -->
         <el-form label-width="105px" style="line-height: 26px;">
               <el-dialog :title="$t('msg.wifi_open_close_tip')" :visible.sync="dialogTemp" width="510px">
                  <div class="config-box">
                     <el-row class="m-bottom-15">
                           <el-col :span="7" class="t-a-right" v-text="$t('msg.operating_a')">操作：</el-col>
                           <el-col :span="17">
                              <el-switch v-model="onOffState" @change="setTemp" active-value="TURNON" inactive-value="TURNOFF"></el-switch>
                           </el-col>
                     </el-row>
                  </div>
               </el-dialog>
         </el-form>
      </el-row>
   </div>
</template>
<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   import hotel from "../../hotelManage/hotelList/hotel/hotel";
   export default {
      name: 'hotelAll',
      data () {
         return {
            that: this,
            tableData: [],        // 设备列表
            hotelList: [],        // 酒店列表
            hotelId: '',          // 酒店ID
            buildList: [],        // 楼栋单元列表
            emptyFloor: [],       // 空楼层列表
            floors: [],           // 楼层列表
            roomList: [],         // 房间列表
            loading: true,        // 表格加载效果
            total: 0,             // 表格数据总数
            need: '',             // 是否需要返回
            dialogTemp: false,    // 温控面板弹窗
            dialogCurtain: false, // 窗帘电机弹窗
            dialogSocket: false,  // 插座弹窗
            dialogSwitch: false,  // 开关弹窗
            dialogDimming: false, // 调光面板弹窗
            operWidth: 0,         // 操作栏宽度
            typeWidth: 0,         // 类型栏宽度
            /* 查询设备 */
            deviceNameQ: '',      // 设备名称
            roomId: '',           // 房间ID
            floorNo: '',          // 楼层号
            state: '',            // 在线状态
            buildUnitId: '',      // 楼栋单元ID
            limit: 0,             // 每页数
            page: 1,              // 当前页
            /* 设置设备 */
            id: '',               // 设备ID
            deviceName: '',       // 设备名称
            onOffState: '',       // 温控面板状态
            curtainState: '',     // 窗帘电机状态
            socketState: '',      // 插座状态
            switchList: [],       // 开关状态列表
            currentLevel: 0,      // 调光面板亮度
            /* 提示文本 */
            confirm: '确定',
            unable_to_wake: '该设备没有加入网关，无法唤醒！',
            instruction_has_been_issued: '指令已发出，请稍后...',
            open_command_has_been_issued: '设备打开指令已发出，请稍后...',
            close_command_has_been_issued: '设备关闭指令已发出，请稍后...',
            wake_up_command_has_been_issued: '设备唤醒指令已发出,请观察该网关下面的设备状态！',
            update_success: '修改成功！',
            operate_success: '操作成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            own_hotel_no_null: '所属酒店不能为空！',
            floor_no_null: '楼层不能为空！',
            dev_location_no_null: '设备位置不能为空！',
            room_no_null: '房间号不能为空！',
            confirm_wake_txt: '若唤醒该设备，将会重启该房间的网关，属于该房间的所有设备将会被重启，确定唤醒？',
         }
      },
      computed: {
         ...mapState(['dictData','hotelInfo'])
      },
      inject: ['reload'],
      beforeRouteUpdate(to, from, next){
         this.$store.dispatch('setCachePage', 'fakePage')
         this.handleQuery(false)
         this.need = ''
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'hotelDeviceEdit' || to.name === 'hotelDeviceDetail'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted () {
         getDict(['device-location'])
         this.need = this.$route.query.need
         // this.roomId = this.$route.query.roomId
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getHotels()
      },
      methods: {
         //面包屑回显
         fullPathEvent(path){
           switch (path){
              case "/hotel_all/all":
                 return "全部"
              case "/hotel_all/HOGW":
                 return "网关"
              case "/hotel_all/HOSW":
                 return "开关面板"
              case "/hotel_all/HOSC":
                 return "场景面板"
              case "/hotel_all/HOSO":
                 return "插座"
              case "/hotel_all/HODS":
                 return "门磁感应器"
              case "/hotel_all/HOSA":
                 return "烟雾感应器"
              case "/hotel_all/HOGS":
                 return "红外感应器"
              case "/hotel_all/HODR":
                 return "窗帘电机"
              case "/hotel_all/HORA":
                 return "窗帘导轨"
              case "/hotel_all/HOSL":
                 return "智能锁"
              case "/hotel_all/HOIN":
                 return "红外宝"
              case "/hotel_all/HOTC":
                 return "温控面板"
              case "/hotel_all/HVAC":
                 return "VRV空调面板"
           }
         },

         // 获取酒店列表
         getHotels() {
            const url = urlObj.getHotelList
            this.$axios.post(url, {}, 'json').then(res => {
               if (res.success) {
                  let hotelList = res.records
                  hotelList.unshift({id: '', hotelName: '全部'})
                  this.hotelList = hotelList.map(item => {
                     return {value: item.id, label: item.hotelName}
                  })
                   if (this.$route.query.hotelId){
                     this.hotelId = this.$route.query.hotelId
                  }else {
                       this.hotelId = this.hotelList[0].value
                  }
                  this.getDeviceList()
               }

            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取设备列表
         getDeviceList() {
            let param = {
               deviceName: this.deviceNameQ,
               roomId: this.roomId,
               floorNo: this.floorNo,
               state: this.state,
               buildUnitId: this.buildUnitId[1],
            }
            // 如果酒店设备导航选中'全部' 则把全部设备类型发给后台
            const typeCode = this.$route.params.device_type_id
            if (typeCode === 'all') {
               param.productIds = ['HOTCW', 'HOINW', 'HOSOW', 'HOGWZ', 'HODRW', 'HAVCGW']
            }else if (typeCode === 'HVAC') {
               param.productIds = ['HAVCGW']
            }else {
               param.typeCode = typeCode
            }
            // 如果酒店下拉框选中'全部' 则把全部酒店ID发给后台
            if (!this.hotelId) {
               /*let hotelIds = []
              this.hotelList.forEach(item => item.value && hotelIds.push(item.value))
              param.hotelIds = hotelIds*/
               param.hotelIds = [this.hotelInfo.id]
            }else {
               param.hotelId = this.hotelId
            }

            const url = urlObj.devPageForPc + `?limit=${this.limit}&page=${this.page}`
            this.$axios.post(url, param, 'json').then(async res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
                  if (this.total === 0) return this.tableData = []
                  this.tableData.forEach(item => item.leaf && (item.hasChildren = true))
               }
            })
         },
         // 获取设备子节点列表
         load(tree, treeNode, resolve) {
            const url = urlObj.getByGatewayId
            const param = { parentId: tree.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let tableData = res.records
                  resolve(tableData)
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前选中酒店
         changeHotel(val) {
            this.hotelId = val
            this.getBuildList()
         },
         // 获取楼栋单元列表
         getBuildList() {
            const url = urlObj.buildAndUnitList + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item => {
                     let children = item.buildUnits.map(item => {
                        return {value: item.id, label: item.unitName}
                     })
                     return {value: item.id, label: item.buildName, children}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前选中楼栋
         changeBuild(val) {
            this.buildUnitId = val
            this.getRoomList()
            this.getFloorList()
         },
         // 获取房间列表
         getRoomList() {
            const url = urlObj.getRoomLists
            const param = {
               hotelId: this.hotelId,
               buildUnitId: this.buildUnitId[1],
               floor: this.floor
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomList = res.records
                  this.roomList = roomList.map(item => {
                     return {value: item.id, label: item.roomNo}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取楼层列表
         getFloorList() {
            const url = urlObj.getUnit + `/${this.buildUnitId[1]}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return
                  let floors = JSON.parse(JSON.stringify(this.emptyFloor))
                  let obj = {}
                  for (let i = beginFloor; i <= endFloor; i++) {
                     obj.value = i
                     obj.label = i
                     floors.push(obj)
                     obj = {}
                  }
                  this.floors = floors
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 点击楼层
         clickFloors(val) {
            this.floorNo = val.floor
            this.roomId = ''
            this.getDeviceList()
         },
         // 查询设备列表
         handleQuery(fool) {
            if (fool) return this.getDeviceList()
            this.deviceNameQ = ''
            this.hotelId = ''
            this.buildUnitId = []
            this.deviceName = ''
            this.roomId = ''
            this.floorNo = ''
            this.state = ''
            this.floors = JSON.parse(JSON.stringify(this.emptyFloor))
         },
         // 改变每页数
         changePageNum(num) {
            this.limit = num
            this.getDeviceList()
         },
         // 改变当前页
         changeCurrPage(num) {
            this.page = num
            this.getDeviceList()
         },
         // 查看详情
         handleDetail(row) {
            this.$router.push({
               path: '/hotel_device_detail',
               query: {
                  id: row.id,
                  type: 2,
                  hotelName: row.hotelName,
                  location: row.location
               }
            })
         },
         // 编辑设备
         handleEdit(row) {
            this.$router.push({
               path: '/hotel_device_edit',
               query: {
                  id: row.id,
                  type: 2,
                  hotelId: row.hotelId
               }
            })
         },
         // 删除设备
         handleDel(deviceId) {
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.deleteDeviceById
               const param = { deviceId }
               this.$axios.post(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getDeviceList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 一键唤醒
         handleAwaken(parentId){
            this.$confirm(this.confirm_wake_txt, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               if (!parentId) { return this.$message(this.unable_to_wake) }
               const url = urlObj.devicWakeUp
               const param = { gatewayId: parentId }
               this.$axios.get(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.wake_up_command_has_been_issued,
                        type: 'success'
                     })
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 获取开关面板状态
         getSwitch(row) {
            this.dialogSwitch = true
            this.deviceName = row.deviceName
            this.id = row.id
            const url = urlObj.getDevDetail
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let switchList = res.records.switchList
                  this.switchList = switchList.map(item => {
                     return {alias: item.alias, state: item.state}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置开关面板
         setSwitch(switchState, indexs) {
            const url = urlObj.setSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               indexs,
               switchState,
               operationAction: 'SWITCH'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取调光面板
         getDimming(row){
            this.dialogDimming = true
            this.id = row.id
            this.deviceName = row.deviceName
         },
         // 设置调光面板
         setDimming(){
            const url = urlObj.setSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               currentLevel: String(this.currentLevel),
               onLevel: String(this.currentLevel),
               operationAction: 'DIMMING'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取温控面板
         getTemp(row) {
            // 开启弹窗
            this.dialogTemp = true
            // 存储设置时所需字段
            this.id = row.id
            this.deviceName = row.deviceName
            const url = urlObj.getDevTemperature
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.onOffState = res.records.onoff
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置温控面板
         setTemp() {
            const url = urlObj.setSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               onOffState: this.onOffState,
               operationAction: 'TEMPPANEL'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.operate_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置智能锁
         setLock(row) {
            const url = urlObj.setSendIotMsg
            const param = {
               id: row.id,
               operationAction: 'UNLOCK',
               deviceName: row.deviceName
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.instruction_has_been_issued,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取窗帘电机
         getCurtainMotor(row) {
            this.id = row.id
            this.deviceName = row.deviceName
            this.dialogCurtain = true
         },
         // 设置窗帘电机
         setCurtainMotor() {
            let url = urlObj.setSendIotMsg
            let param = {
               id: this.id,
               deviceName: this.deviceName,
               indexs: 1,
               curtainState: this.curtainState,
               operationAction: 'CURTAIN'
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.close_command_has_been_issued,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取插座
         getSocket(row) {
            this.dialogSocket = true
            this.id = row.id
            this.deviceName = row.deviceName
            const url = urlObj.getDevDetail
            const param = { deviceId: row.id }
            this.$axios.post(url, param).then(res => {
               this.socketState = res.records[0].state
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 设置插座
         setSocket() {
            const url = urlObj.setSendIotMsg
            const param = {
               id: this.id,
               deviceName: this.deviceName,
               switchState: this.socketState,
               indexs: 1,
               operationAction: 'SWITCH'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.close_command_has_been_issued,
                     type: 'success'
                  })
                  this.dialogSocket = false
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 返回房间设备页
         goBack(){
            sessionStorage.setItem('menuIndex', '1')
            sessionStorage.setItem('sideIndex', '3-1')
            this.$router.go(-1)
            this.reload()
         }
      },
      filters: {
         filterLocation(val, that) {
            if (val) return that.dictData['device-location'][val]
         }
      },
      watch: {
         $route(to) {
            if (to.name === 'hotelAll'){
               this.getDeviceList()
            }
         },
         hotelInfo(newVal,oldVal){
            oldVal && newVal.id !== oldVal.id && this.getHotels()
         }
      },
   }
</script>

<style lang="scss">
   .not-check{
       .el-table__row td:first-of-type{
          text-indent: 27px;
       }
       .el-table__row--level-0 td,
       .el-table__row--level-1 td,
       .el-table__row--level-2 td,
       .el-table__row--level-3 td{
          text-indent: 0!important;
       }
    }

    .gree-btn{
        background:#009688;
        border-color: #009688;
        color:white;
        &:hover, &:focus {
            color: #1ABC9C ;
            border-color: #baebe1;
            background-color: #e8f8f5;
            opacity: 0.8;
        }
        &:active{
            opacity: 1!important;
        }
    }
    .tip-txt{
        display:block;
        width:200px;
        color: #FFB800;
        line-height:20px;
    }
    .el-row{
        line-height: 32px;
    }
    .floor-bottom{
        /deep/.el-tabs__header{
            margin-bottom: 0;
        }
    }
</style>
